<template>
  <div class="check_btn">
    <div
      :class="{ isActive: !showColorIndex }"
      @click="check_theScopeOf_date(0)"
    >
      Last 5 Days
    </div>
    <div
      :class="{ isActive: showColorIndex }"
      @click="check_theScopeOf_date(1)"
    >
    Last Month
    </div>
  </div>
  <div id="inquiry"></div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  onBeforeMount,
  onUnmounted,
} from 'vue'
import { rfqStatistics } from '@/api/performance'
import * as echarts from 'echarts'
import handler_date from '@/utils/handler_date'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: ['date', 'userId'],
  setup(props, context) {
    let router = useRouter()
    let showColorIndex = ref(0)
    let myEcharts = ref(null)
    const option = reactive({
      legend: {
        show: true,
        right: 0,
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          rotate: 25,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Inquiry',
          data: [],
          type: 'line',
        },
        {
          name: 'RFQ',
          data: [],
          type: 'line',
        },
      ],
    })
    function check_theScopeOf_date(d) {
      showColorIndex.value = d
    }
    function getRfqStatistics(newTimeObj = null) {
      const submitform = newTimeObj ? newTimeObj : props.date
      if (router.currentRoute.value.query.userId) {
        submitform.userId = router.currentRoute.value.query.userId
      } else {
        submitform.userId = props.userId || 0
      }
      rfqStatistics(submitform).then((res) => {
        option.xAxis.data = []
        option.series[0].data = []
        option.series[1].data = []
        res.data.forEach((el) => {
          option.xAxis.data.push(el.date)
          option.series[0].data.push(el.inquiryCount)
          option.series[1].data.push(el.rfqCount)
        })
        if (myEcharts) {
          myEcharts.dispose()
        }
        myEcharts = echarts.init(document.getElementById('inquiry'))
        myEcharts.setOption(option)
      })
    }
    onMounted(() => {
      myEcharts = echarts.init(document.getElementById('inquiry'))
      option && myEcharts.setOption(option)
      window.addEventListener('resize', function () {
        myEcharts.resize()
      })
    })
    onBeforeMount(() => {
      getRfqStatistics()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', function () {
        myEcharts.resize()
      })
    })
    watch(
      () => props.date.endTime,
      (newValue, oldValue) => {
        if (newValue) {
          getRfqStatistics()
        } else {
          getRfqStatistics(handler_date())
        }
      },
      {
        immediate: false,
        deep: true,
      }
    )
    watch(
      () => showColorIndex.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue == 1) {
            getRfqStatistics(handler_date(30))
          } else {
            getRfqStatistics(handler_date())
          }
        }
      },
      { immediate: false, deep: true }
    )
    watch(
      () => props.userId,
      (newValue, oldValue) => {
        getRfqStatistics()
      },
      { deep: true, immediate: false }
    )
    return {
      showColorIndex,
      check_theScopeOf_date,
    }
  },
})
</script>

<style lang="less" scoped>
.check_btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  > div {
    width: 120px;
  }
}
#inquiry {
  min-height: 400px;
}
.isActive {
  color: #4e88fe;
}
</style>
